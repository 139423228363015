<template>
  <div>
    <v-expansion-panels v-model="panel" flat multiple>
      <v-expansion-panel :class="`${outlined ? 'outlined' : ''}`">
        <v-progress-linear
          v-if="showLoading"
          indeterminate
          color="primary"
        ></v-progress-linear>
        <v-expansion-panel-header
          class="grey--text text--darken-2 font-weight-medium text-body-1"
        >
          <v-row align-content="center">
            <!-- <v-col class="d-block" cols="9"> -->
            <v-col class="d-block">
              <div class="mx-0 pt-0 px-0">
                {{ $t("common.configAlternativeEmails") }} ({{
                  $t("common.emailsAliases")
                }})
              </div>
              <small class="mx-0 pt-0 px-0">{{
                $t("common.receiveEmailsLike")
              }}</small>
            </v-col>
            <!-- <v-col cols="3" style="margin-top: 11px">
              <v-play-video-button
                title="Receber e-mail como"
                id_youtube_video="ygPflAvriSA"
                class="align-self-center"
              />
            </v-col> -->
          </v-row>
        </v-expansion-panel-header>

        <v-expansion-panel-content
          :class="`pb-0 ${overlayScopes ? 'pa-3' : ''}`"
          style="position: relative"
        >
          <v-card-text class="mx-0 pt-0 px-0">
            {{ $t("common.configAlternativeEmailsDescription") }}
          </v-card-text>

          <v-simple-table class="mb-4">
            <template v-slot:default>
              <tbody>
                <tr v-for="item in items" :key="item.email">
                  <td>{{ item.alias }}</td>

                  <td align="end">
                    <v-btn
                      text
                      small
                      class="ma-2"
                      outlined
                      color="accent"
                      dark
                      @click="
                        (showDialogDeleteItem = true), (alias_to_delete = item)
                      "
                      >{{ $t("action.delete") }}
                    </v-btn>

                    <v-dialog
                      :retain-focus="false"
                      v-model="showDialogDeleteItem"
                      width="500"
                      @keydown.esc="$emit(`close`)"
                      @click:outside="$emit(`close`)"
                      content-class="elevation-0"
                      overlay-opacity="0.2"
                    >
                      <v-card>
                        <h2 class="title py-3 px-6">
                          {{ $t("common.confirmDeletion") }}
                        </h2>
                        <v-card-text
                          >{{ $t("common.confirmDeletionDescription") }}
                          {{ alias_to_delete?.alias }}?</v-card-text
                        >
                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="secondary"
                            text
                            @click="showDialogDeleteItem = false"
                            >{{ $t("action.cancel") }}</v-btn
                          >
                          <v-btn
                            color="#311F50"
                            text
                            @click="
                              deleteAlias(alias_to_delete),
                                (showDialogDeleteItem = false)
                            "
                            >{{ $t("action.confirm") }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-tooltip :disabled="userHasGmail" bottom>
            <template v-slot:activator="{ on, attrs }">
              <div style="width: fit-content" v-bind="attrs" v-on="on">
                <v-btn
                  :color="`${isOnboardingPage ? 'primarySuite' : 'accent'}`"
                  @click="dialogAddUserAlias = true"
                  :disabled="showLoading || !userHasGmail || overlayScopes"
                  class="mt-0 elevation-0 text-none font-weight-medium text-body-2 white--text"
                >
                  {{ $t("common.addAlternativeEmail") }}
                </v-btn>
              </div>
            </template>
            <span v-if="!userHasGmail">
              {{ $t("common.inactiveEmailService") }}
            </span>
          </v-tooltip>

          <!-- DIALOG ADC NOVO EMAIL -->
          <HeaderDialog
            id="add_user_email_alias"
            :title="$t('common.addAlternativeEmail')"
            :show="dialogAddUserAlias"
            width="600"
            :loading="showLoading"
            :disabled-action="!filled"
            @action="addUserAlias()"
            @close="clearInputs()"
            :persistent="false"
          >
            <template v-slot:body>
              <p class="pb-2">
                {{ $t("common.addAlternativeEmailDescription") }}<br />
                Ex.: comercial@, contato@, financeiro@…
              </p>

              <v-row align="baseline" class="ma-0 pa-0">
                <v-col class="pa-0">
                  <v-text-field
                    class="py-0"
                    v-model="newItem.username"
                    :label="`${$t('common.alternativeEmail')} (${$t(
                      'common.emailsAliases'
                    )})`"
                    :rules="[rules.required]"
                    :disabled="showLoading"
                  />
                </v-col>
                <v-col class="pa-0">
                  <v-select
                    class="py-0 mt-2"
                    v-model="newItem.domain"
                    :items="domains"
                    :label="$t('common.domain')"
                    :rules="[rules.required]"
                    :disabled="showLoading"
                    prepend-icon="mdi-at"
                  />
                </v-col>
              </v-row>
            </template>
          </HeaderDialog>
          <!-- OVERLAY FOR SCOPES MISSING  -->
          <OverlayScopes
            :overlay="overlayScopes"
            :functionality="$t('common.configAlternativeEmails')"
            dense
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import HeaderDialog from "@/components/base/HeaderDialog.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import {
  errorMessages,
  successMessages,
} from "@/helpers/variables/snackbarMessages";
import OverlayScopes from "../general/OverlayScopes.vue";

export default {
  name: "EmailAliases",
  components: { HeaderDialog, OverlayScopes },
  props: {
    user: { type: Object, default: () => {} },
    outlined: { type: Boolean, default: false },
    isPanelOpened: { type: Boolean, default: false },
  },
  data() {
    return {
      panel: [this.isPanelOpened ? 0 : 1],
      showLoading: false,
      newItem: {
        username: "",
        domain: "",
      },
      items: [],
      dialogAddUserAlias: false,
      showDialogDeleteItem: null,
      alias_to_delete: null,
      disabled: "disabled",
      rules: {
        required: (v) => !!v || this.$t("common.requiredField"),
      },
      overlayScopes: false,
    };
  },

  computed: {
    ...mapGetters(["currentUser", "token", "domains"]),

    userHasGmail() {
      return !!this.user?.is_gmail_enabled;
    },

    isOnboardingPage() {
      if (this.$route.name === "Onboarding") return true;
      return false;
    },

    filled() {
      return !!this.newItem?.username && !!this.newItem?.domain;
    },
  },

  methods: {
    ...mapMutations(["setSnackBar"]),
    ...mapActions(["completeMission", "getUserAliasesByUserId"]),

    async getUserAliases() {
      this.disabled = "disabled";
      this.showLoading = true;

      const result = await this.getUserAliasesByUserId(this.user);

      if (result === "INVALID_GOOGLE_ACCESS_TOKEN") {
        this.overlayScopes = true;
        this.disabled = true;
        this.showLoading = false;
        return;
      }

      this.disabled = false;
      this.items = result;
      this.showLoading = false;
    },

    createAlias() {
      this.showLoading = true;

      const url = `${process.env.VUE_APP_API_BASE_URL}/users/${this.user.key}/aliases`;
      const payload = {
        alias: this.newItem.username + "@" + this.newItem.domain,
      };
      const auth = { headers: { Authorization: this.token } };

      this.$axios
        .post(url, payload, auth)
        .then(({ data }) => {
          this.items.push({
            id: data.id,
            alias: data.alias,
          });
          this.setSnackBar({
            show: true,
            message: successMessages.apply_24hours,
            color: "success",
          });
        })
        .catch((err) => {
          console.error("createAlias()", err);
          this.setSnackBar({
            show: true,
            message: errorMessages.unknown,
            color: "error",
          });
        })
        .finally(() => {
          this.showLoading = false;
        });
    },

    addUserAlias() {
      this.createAlias();
      this.clearInputs();
    },

    clearInputs() {
      this.dialogAddUserAlias = false;
      this.newItem = {
        username: "",
        domain: "",
      };
    },

    deleteAlias(alias_to_delete) {
      this.showLoading = true;

      const url = `${process.env.VUE_APP_API_BASE_URL}/users/${this.user.key}/aliases`;
      const data = { alias: alias_to_delete.alias };
      const headers = { Authorization: this.token };

      this.$axios
        .delete(url, { data, headers })
        .then(() => {
          this.items.splice(
            this.items.findIndex((item) => item.alias == alias_to_delete.alias),
            1
          );
          this.setSnackBar({
            show: true,
            message: successMessages.delete_24hours,
            color: "success",
          });
        })
        .catch((err) => {
          console.log("deleteAlias()", err);
          this.setSnackBar({
            show: true,
            message: errorMessages.unknown,
            color: "error",
          });
        })
        .finally(() => {
          this.showDialogDeleteItem = false;
          this.showLoading = false;
        });
    },
  },

  watch: {
    user() {
      if (this.userHasGmail) this.getUserAliases();
    },
    panel() {
      if (this.userHasGmail && !this.items.length) {
        this.getUserAliases();
      }
    },
  },

  mounted() {
    if (this.userHasGmail && this.isPanelOpened) this.getUserAliases();
  },
};
</script>
