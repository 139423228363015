var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.currentOnboardingStep > 1 && _vm.currentOnboardingStep < 8)?_c('div',{staticClass:"onboarding-progress-bar"},[(_vm.$vuetify.breakpoint.smAndUp)?_c('v-stepper',{staticClass:"transparent elevation-0",attrs:{"alt-label":_vm.$vuetify.breakpoint.sm,"readonly":"","value":_vm.currentStep}},[_c('v-stepper-header',[_c('v-stepper-step',{attrs:{"step":"1","complete":_vm.currentStep > 1,"color":"accent"},on:{"click":function($event){return _vm.handleStep(1)}}},[_c('div',{class:`d-flex text-center text-wrap normal-line-height ${
            _vm.currentStep === 1 ? 'font-weight-medium grey--text' : ''
          } ${_vm.currentStep > 1 ? 'font-weight-medium grey--text' : ''}`},[_vm._v(" "+_vm._s(_vm.$t("common.createUser"))+" ")])]),_c('v-stepper-step',{attrs:{"step":"2","complete":_vm.currentStep > 2,"color":"accent"},on:{"click":function($event){return _vm.handleStep(3)}}},[_c('div',{class:`d-flex text-center text-wrap normal-line-height ${
            _vm.currentStep === 2 ? 'font-weight-bold grey--text' : ''
          } ${_vm.currentStep > 2 ? 'font-weight-medium grey--text' : ''}`},[_vm._v(" "+_vm._s(_vm.$t("common.emailSignatureInfomations"))+" ")])]),_c('v-stepper-step',{attrs:{"step":"3","complete":_vm.currentStep > 3,"color":"accent"},on:{"click":function($event){return _vm.handleStep(4)}}},[_c('div',{class:`d-flex text-center text-wrap normal-line-height ${
            _vm.currentStep === 3 ? 'font-weight-bold grey--text' : ''
          } ${_vm.currentStep > 3 ? 'font-weight-medium grey--text' : ''}`},[_vm._v(" "+_vm._s(_vm.$t("common.userInfos"))+" ")])]),_c('v-stepper-step',{attrs:{"step":"4","complete":_vm.currentStep > 4,"color":"accent"},on:{"click":function($event){return _vm.handleStep(5)}}},[_c('div',{class:`d-flex text-center text-wrap normal-line-height ${
            _vm.currentStep === 4 ? 'font-weight-bold grey--text' : ''
          } ${_vm.currentStep > 4 ? 'font-weight-medium grey--text' : ''}`},[_vm._v(" "+_vm._s(_vm.$t("common.emailSignature"))+" ")])]),_c('v-stepper-step',{attrs:{"step":"5","complete":_vm.currentStep > 5,"color":"accent"},on:{"click":function($event){return _vm.handleStep(6)}}},[_c('div',{class:`d-flex text-center text-wrap normal-line-height ${
            _vm.currentStep === 5 ? 'font-weight-bold grey--text' : ''
          } ${_vm.currentStep > 5 ? 'font-weight-medium grey--text' : ''}`},[_vm._v(" "+_vm._s(_vm.$t("common.accessControl"))+" ")])]),_c('v-stepper-step',{attrs:{"step":"6","complete":_vm.currentStep > 6,"color":"accent"},on:{"click":function($event){return _vm.handleStep(7)}}},[_c('div',{class:`d-flex text-center text-wrap normal-line-height ${
            _vm.currentStep === 6 ? 'font-weight-bold grey--text' : ''
          } ${_vm.currentStep > 6 ? 'font-weight-medium grey--text' : ''}`},[_vm._v(" "+_vm._s(_vm.$t("onboardingNewUser.companyResources"))+" ")])])],1)],1):_vm._e(),_c('v-progress-linear',{class:`rounded-t-xl`,attrs:{"background-color":"grey lighten-2","color":"accent","value":_vm.progress,"height":"7"}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }