<template>
  <v-container fluid class="d-flex flex-column fill-height">
    <v-card
      v-if="loaded"
      tile
      flat
      class="elevation-0 overflow-y-auto ma-auto ml-10 mr-10"
      height="calc(89vh - 80px)"
      max-width="90vw"
    >
      <alert
        class="mt-2"
        dense
        color="warning"
        v-if="!userById.is_gmail_enabled"
      >
        <div class="d-flex flex-inline align-center justify-space-between">
          <span>{{ $t("common.inactiveUserInWorkspaceAlert") }}</span>

          <div :class="`${$vuetify.breakpoint.smAndDown ? '' : 'd-flex'}`">
            <v-btn
              text
              small
              color="secondary lighten-2"
              class="mr-2 text-body-2 font-weight-medium"
              @click="
                redirectToGoogleUserInWorkspace(userById.id_google, 'licenses')
              "
              :loading="localLoading"
            >
              {{ $t("action.assignLicense") }}
              <v-icon right v-text="'mdi-open-in-new'" class="mr-3" size="20" />
            </v-btn>
            <v-btn
              text
              small
              outlined
              color="warning"
              :class="`ml-auto text-body-2 font-weight-medium ${
                $vuetify.breakpoint.smAndDown ? 'mt-2' : ''
              }`"
              @click="checkUserById()"
              :loading="localLoading"
            >
              {{ $t("action.reloadUserInfo") }}
              <v-icon right v-text="'mdi-reload'" class="mr-3" size="20" />
            </v-btn>
          </div>
        </div>
      </alert>
      <v-card-title class="pa-2 ml-16">
        {{ $t("common.userInfos") }}
      </v-card-title>
      <v-row style="width: 89vw" class="ma-0 pa-0" justify="center">
        <v-col
          :cols="`${$vuetify.breakpoint.mdAndUp ? '11' : '12'}`"
          class="mt-4"
        >
          <UserGroups
            class="ms-1"
            :user="userById"
            @addSendAs="addSendAs"
            :isPanelOpened="true"
            outlined
          />
        </v-col>
        <v-col
          :cols="`${$vuetify.breakpoint.mdAndUp ? '11' : '12'}`"
          class="mt-4"
        >
          <EmailAliases
            :isPanelOpened="true"
            class="ms-1"
            :user="userById"
            outlined
          />
        </v-col>
        <v-col
          :cols="`${$vuetify.breakpoint.mdAndUp ? '11' : '12'}`"
          class="mt-0"
        >
          <SendAs
            class="mr-1"
            :user="userById"
            :getUserSendAs="updateSendAs"
            :isPanelOpened="true"
            outlined
          />
        </v-col>
      </v-row>
    </v-card>

    <v-card v-else flat class="pa-0 ma-0">
      <v-row class="ma-0 pa-0" style="width: 90vw">
        <v-col
          v-for="key in [1, 2, 3, 4]"
          :key="key"
          class="pt-0"
          cols="12"
          sm="12"
          md="12"
          lg="12"
        >
          <div><v-skeleton-loader type="text" /></div>
          <div><v-skeleton-loader type="image" /></div>
        </v-col>
      </v-row>
    </v-card>
    <v-divider style="width: 90vw"></v-divider>
    <v-footer
      rounded
      style="height: 70px"
      color="background"
      class="border-bottom rounded-xl mt-3"
      width="90vw"
    >
      <v-btn
        text
        height="52"
        color="secondary"
        style="border: 2px solid grey !important"
        class="mx-auto text-none text-body-1 font-weight-medium px-8"
        outlined
        @click="$emit('back')"
        :disabled="loading"
      >
        <v-icon right v-text="'mdi-arrow-left'" class="mr-4" size="22" />
        {{ $t("common.back") }}
      </v-btn>

      <v-spacer />

      <v-btn
        height="52"
        color="accent"
        class="mx-auto text-none text-body-1 font-weight-bold px-8 mr-0"
        @click="$emit('next')"
        :loading="loading"
      >
        {{ $t("action.next") }}
        <v-icon right v-text="'mdi-arrow-right'" class="ml-4" size="22" />
      </v-btn>
    </v-footer>
    <Snackbar
      :show="show"
      :color="snackColor"
      :message="snackMessage"
      @close="show = false"
      notTimeout
    />
  </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { setFirebaseEvent } from "@/helpers/services/registerAnalytics.js";
import SendAs from "../user/SendAs.vue";
import EmailAliases from "../user/EmailAliases.vue";
import UserGroups from "../user/UserGroups.vue";
import { redirectToGoogleUserInWorkspace } from "@/helpers/services/users";
export default {
  name: "UpdateUser",
  components: {
    SendAs,
    EmailAliases,
    UserGroups,
  },

  props: {},

  data() {
    return {
      loading: false,
      updateSendAs: false,
      snackColor: "",
      snackMessage: "",
      show: false,
      localLoading: false,
    };
  },

  computed: {
    ...mapGetters(["token", "userById", "mainDomain", "currentUser"]),

    loaded() {
      return !this.loading && this.userById?.id_google;
    },
  },

  methods: {
    ...mapMutations(["setSnackBar", "setCurrentUser", "setUsersCustomTags"]),
    ...mapActions(["getUserById"]),

    setFirebaseEvent,

    saveAnalytics(log) {
      if (this.mainDomain) {
        this.setFirebaseEvent(this.mainDomain, log.event, log.click);
      }
    },

    addSendAs() {
      this.updateSendAs = true;
    },

    async checkUserById() {
      const formData = JSON.parse(localStorage.getItem("formData")) || {};

      const id =
        formData.user.id_google ||
        JSON.parse(localStorage.getItem("userId")) ||
        this.userById.id_google;

      this.localLoading = true;
      await this.getUserById(id).finally(() => {
        this.localLoading = false;
      });
    },

    redirectToGoogleUserInWorkspace,
  },
  async beforeMount() {
    const userCreated = localStorage.getItem("userCreated");
    if (
      (!this.userById?.email && this.userById?.email !== userCreated?.email) ||
      !this.userById?.is_gmail_enabled
    ) {
      await this.checkUserById();
    }
  },
};
</script>
<style scoped></style>
