<template>
  <v-card flat class="d-flex flex-column align-center" max-width="550px">
    <div
      class="d-flex align-center justify-center text-center mt-4"
      style="
        background-color: #f3e4f8;
        border-radius: 50%;
        width: 70px;
        height: 70px;
      "
    >
      <v-icon size="35">{{ primaryIcon }}</v-icon>
    </div>
    <v-card-title
      class="text-wrap break-word font-weight-medium text-body-1"
      style="color: #7e7e7e"
    >
      {{ $t(title) }}
    </v-card-title>
    <v-card-subtitle class="mt-1 font-weight-medium" v-if="subtitle">
      {{ $t(subtitle) }}
    </v-card-subtitle>
    <v-card-actions v-if="buttonText">
      <v-btn
        color="#F1E1F7"
        class="mx-auto text-none text-body-1 font-weight-bold px-8 mr-0"
        @click="$emit('action')"
        style="color: #8f71d5"
      >
        {{ $t(buttonText) }}
        <v-icon right class="ml-4" size="22">{{ buttonIcon }}</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: "EmptyStateCard",
  props: {
    primaryIcon: { type: String, default: "ph-shield-warning" },
    title: { type: String, required: true },
    subtitle: { type: String },
    buttonText: { type: String },
    buttonIcon: { type: String, default: "ph-arrow-right" },
  },

  methods: {},
};
</script>
<style lang=""></style>
