<template>
  <div
    v-if="currentOnboardingStep > 1 && currentOnboardingStep < 8"
    class="onboarding-progress-bar"
  >
    <v-stepper
      v-if="$vuetify.breakpoint.smAndUp"
      :alt-label="$vuetify.breakpoint.sm"
      readonly
      class="transparent elevation-0"
      :value="currentStep"
    >
      <v-stepper-header>
        <v-stepper-step
          step="1"
          :complete="currentStep > 1"
          color="accent"
          @click="handleStep(1)"
        >
          <div
            :class="`d-flex text-center text-wrap normal-line-height ${
              currentStep === 1 ? 'font-weight-medium grey--text' : ''
            } ${currentStep > 1 ? 'font-weight-medium grey--text' : ''}`"
          >
            {{ $t("common.createUser") }}
          </div>
        </v-stepper-step>

        <v-stepper-step
          step="2"
          :complete="currentStep > 2"
          color="accent"
          @click="handleStep(3)"
        >
          <div
            :class="`d-flex text-center text-wrap normal-line-height ${
              currentStep === 2 ? 'font-weight-bold grey--text' : ''
            } ${currentStep > 2 ? 'font-weight-medium grey--text' : ''}`"
          >
            {{ $t("common.emailSignatureInfomations") }}
          </div>
        </v-stepper-step>

        <v-stepper-step
          step="3"
          :complete="currentStep > 3"
          color="accent"
          @click="handleStep(4)"
        >
          <div
            :class="`d-flex text-center text-wrap normal-line-height ${
              currentStep === 3 ? 'font-weight-bold grey--text' : ''
            } ${currentStep > 3 ? 'font-weight-medium grey--text' : ''}`"
          >
            {{ $t("common.userInfos") }}
          </div>
        </v-stepper-step>

        <v-stepper-step
          step="4"
          :complete="currentStep > 4"
          color="accent"
          @click="handleStep(5)"
        >
          <div
            :class="`d-flex text-center text-wrap normal-line-height ${
              currentStep === 4 ? 'font-weight-bold grey--text' : ''
            } ${currentStep > 4 ? 'font-weight-medium grey--text' : ''}`"
          >
            {{ $t("common.emailSignature") }}
          </div>
        </v-stepper-step>

        <v-stepper-step
          step="5"
          :complete="currentStep > 5"
          color="accent"
          @click="handleStep(6)"
        >
          <div
            :class="`d-flex text-center text-wrap normal-line-height ${
              currentStep === 5 ? 'font-weight-bold grey--text' : ''
            } ${currentStep > 5 ? 'font-weight-medium grey--text' : ''}`"
          >
            {{ $t("common.accessControl") }}
          </div>
        </v-stepper-step>

        <v-stepper-step
          step="6"
          :complete="currentStep > 6"
          color="accent"
          @click="handleStep(7)"
        >
          <div
            :class="`d-flex text-center text-wrap normal-line-height ${
              currentStep === 6 ? 'font-weight-bold grey--text' : ''
            } ${currentStep > 6 ? 'font-weight-medium grey--text' : ''}`"
          >
            {{ $t("onboardingNewUser.companyResources") }}
          </div>
        </v-stepper-step>
      </v-stepper-header>
    </v-stepper>
    <v-progress-linear
      background-color="grey lighten-2"
      color="accent"
      :value="progress"
      height="7"
      :class="`rounded-t-xl`"
    />
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "OnboardingProgressBar",

  data() {
    return {};
  },

  computed: {
    ...mapGetters(["currentOnboardingStep", "onboardingStepsNumber"]),

    progress() {
      return parseInt((this.currentStep * 100) / this.onboardingStepsNumber);
    },

    currentStep() {
      return parseInt(this.currentOnboardingStep) - 1;
    },
  },

  methods: {
    ...mapMutations(["setOnboardingStep", "setSnackBar"]),

    handleStep(step) {
      const formData = JSON.parse(localStorage.getItem("formData")) || {};

      if (
        formData.user &&
        !formData.selectUser &&
        !localStorage.getItem("userCreated") &&
        this.currentStep === 1
      ) {
        this.setSnackBar({
          message: this.$t("onboardingNewUser.fillRequiredFields"),
          show: true,
          color: "warning",
        });

        return;
      } else if (!formData.user && this.currentStep === 1) {
        this.setSnackBar({
          message: this.$t("onboardingNewUser.selectOrCreateUser"),
          show: true,
          color: "warning",
        });
      } else {
        this.setOnboardingStep(step);
      }
    },
  },
};
</script>
<style>
.onboarding-progress-bar .v-stepper__step {
  cursor: pointer;
}
</style>
