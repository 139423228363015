<template>
  <v-container fluid class="d-flex flex-column fill-height overflow-auto">
    <div @click="showPermissionProductDialog" class="overflow-auto">
      <v-card
        tile
        flat
        class="d-flex flex-column overflow-auto"
        width="92vw"
        height="calc(89vh - 80px)"
        :loading="loading"
      >
        <v-card-title class="pa-2 mt-5">
          <span class="mr-4">{{ $t("common.subscriptionApp") }}</span>
          <TooltipIcon
            right
            color="info"
            icon="mdi-information"
            :label="$t('common.subscriptionAppTooltipText')"
          />
        </v-card-title>
        <v-card flat class="pa-0 ma-0">
          <v-row class="ma-0">
            <v-col cols="12" sm="5" class="pt-0 pl-0">
              <v-card-actions class="mb-2 px-0">
                <v-text-field
                  v-model="search"
                  :label="$t('common.searchModel')"
                  prepend-inner-icon="mdi-magnify"
                  color="accent"
                  hide-details
                  solo
                  :loading="loading || loadingTemplates"
                  :disabled="loading || loadingTemplates"
                ></v-text-field>
              </v-card-actions>
              <v-expansion-panels
                v-model="expandTemplatesTypes"
                :disabled="loading || loadingTemplates"
                flat
                mandatory
                accordion
                no-data-text="AAA"
              >
                <!-- ÁREA DE MODELOS PERSONALIZADOS -->
                <v-expansion-panel class="outlined">
                  <v-expansion-panel-header
                    class="text-h6 font-weight-medium"
                    :hide-actions="expandTemplatesTypes === 0"
                  >
                    {{ $t("common.customized") }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="ma-0 pa-0">
                    <v-card
                      flat
                      class="ma-0 pa-0 overflow-y-auto"
                      :max-height="thisHeight - 426"
                      min-height="190"
                      :disabled="loading || loadingTemplates"
                    >
                      <v-card-text
                        class="px-0"
                        v-if="customTemplates.length === 0"
                      >
                        {{ $t("common.noCustomModelFound") }}
                      </v-card-text>
                      <v-card-text v-else class="ma-0 pa-0">
                        <v-data-iterator
                          v-model="templateSelected"
                          :loading="loading || loadingTemplates"
                          :items="customTemplates"
                          :search="search"
                          single-select
                          single-expand
                          disable-pagination
                          hide-default-footer
                          :no-data-text="$t('common.noDataAvailable')"
                          :no-results-text="$t('common.noDataFound')"
                        >
                          <template
                            v-slot:default="{ items, isSelected, select }"
                          >
                            <v-row class="ma-0 pa-0">
                              <v-col
                                v-for="(template, i) in items"
                                :key="i"
                                cols="6"
                                class="ma-0 px-0 pr-2"
                              >
                                <CardTemplate
                                  :loading="loading || loadingTemplates"
                                  :info="template"
                                  @selectCard="select(template)"
                                  :selected="isSelected(template)"
                                  class="mb-4"
                                  no-actions
                                  custom
                                />
                              </v-col>
                            </v-row>
                          </template>
                        </v-data-iterator>
                      </v-card-text>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <!-- ÁREA DE MODELOS PADRÃO -->
                <v-expansion-panel class="outlined mt-4">
                  <v-expansion-panel-header
                    class="text-h6 font-weight-medium"
                    :hide-actions="expandTemplatesTypes === 1"
                  >
                    {{ $t("common.default") }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card
                      flat
                      class="ma-0 pa-0 overflow-y-auto"
                      :max-height="thisHeight - 427"
                      min-height="190"
                      :disabled="loading || loadingTemplates"
                    >
                      <v-card-text class="ma-0 pa-0">
                        <v-data-iterator
                          v-model="templateSelected"
                          :loading="loading || loadingTemplates"
                          :items="defaultTemplates"
                          :search="search"
                          single-select
                          single-expand
                          disable-pagination
                          hide-default-footer
                          :no-data-text="$t('common.noDataAvailable')"
                          :no-results-text="$t('common.noDataFound')"
                        >
                          <template
                            v-slot:default="{ items, isSelected, select }"
                          >
                            <v-row class="ma-0 pa-0">
                              <v-col
                                v-for="(template, i) in items"
                                :key="i"
                                cols="6"
                                class="ma-0 px-0 pr-2"
                              >
                                <CardTemplate
                                  :loading="loading || loadingTemplates"
                                  :info="template"
                                  @selectCard="select(template)"
                                  :selected="isSelected(template)"
                                  class="mb-4"
                                  no-actions
                                />
                              </v-col>
                            </v-row>
                          </template>
                        </v-data-iterator>
                      </v-card-text>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
            <v-col cols="12" sm="7" class="pt-0 pr-0">
              <alert
                ref="alert"
                dense
                color="warning"
                v-show="!isGmailEnabled"
                class="mb-0"
              >
                <span>{{ $t("common.inactiveUserInWorkspaceAlert") }}</span>
              </alert>
              <v-card-actions class="mb-2 px-0">
                <v-autocomplete
                  v-model="sendAsSelected"
                  :items="sendAsList"
                  item-text="email"
                  item-value="email"
                  clearable
                  open-on-clear
                  multiple
                  chips
                  return-object
                  :loading="loading || localLoading || loadingSendAs"
                  :disabled="
                    loading || localLoading || loadingSendAs || !isGmailEnabled
                  "
                  :label="$t('common.selectedEmails')"
                  prepend-inner-icon="mdi-email"
                  color="accent"
                  hide-details
                  solo
                >
                  <template
                    v-slot:selection="{ attrs, item, parent, selected }"
                  >
                    <v-chip
                      v-bind="attrs"
                      :color="item.signature?.length ? 'warning' : 'lighten-3'"
                      :input-value="selected"
                      pill
                      class="ma-1 link pl-0"
                      @click.stop="clickSelectedEmail(item)"
                    >
                      <span v-if="item.signature?.length" class="ml-3">
                        <TooltipIcon
                          right
                          color="white"
                          icon="mdi-alert"
                          label="Esse e-mail já possui uma assinatura, e esta será substituída, caso prossiga com a aplicação. Clique para visualizar a assinatura atual. Clique outra vez para retornar ao modelo selecionado anteriormente."
                        />
                      </span>
                      <span class="pr-1 ml-2">
                        {{ item.email }}
                      </span>
                      <v-icon
                        small
                        @click="parent.selectItem(item)"
                        v-text="'mdi-close-circle'"
                      />
                    </v-chip>
                  </template>

                  <template v-slot:item="{ item, attrs, on }">
                    <v-list-item
                      v-on="on"
                      v-bind="attrs"
                      :input-value="itemIsSelected(item)"
                      :color="
                        itemIsSelected(item) ? 'accent lighten-3' : 'white'
                      "
                    >
                      <v-list-item-action class="px-0">
                        <v-checkbox
                          :input-value="itemIsSelected(item)"
                        ></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          <span class="pr-2">
                            {{ item.email }}
                          </span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-card-actions>
              <TemplatePreview
                :html="
                  templatePreviewHtml || `*${$t('common.noModelsSelected')}*`
                "
                :infoToRender="userById"
                :loading="isLoading"
                :title="
                  selectedTemplate.name ||
                  (sendAsPreview?.signature &&
                    `${$t('common.currentSubscriptionTo')} ${
                      sendAsPreview.email
                    }`) ||
                  `*${$t('common.selectAModel')}*`
                "
                hide-bottom
                show-expand
                :sendas-preview="!!sendAsPreview?.signature"
                :max-height="thisHeight - (410 + alertHeight)"
                :show-user-selection="false"
                outlined
              />
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </div>

    <v-divider style="width: 90vw"></v-divider>
    <v-footer
      rounded
      style="height: 70px"
      color="background"
      class="border-bottom rounded-xl mt-3"
      width="90vw"
    >
      <v-btn
        text
        height="52"
        color="secondary"
        style="border: 2px solid grey !important"
        class="mx-auto text-none text-body-1 font-weight-medium px-8"
        outlined
        @click="$emit('back')"
        :disabled="loading || localLoading"
      >
        <v-icon right v-text="'mdi-arrow-left'" class="mr-4" size="22" />
        {{ $t("common.back") }}
      </v-btn>

      <v-spacer />
      <div>
        <v-btn
          height="52"
          color="accent"
          class="mx-auto text-none text-body-1 font-weight-bold px-8 mr-0"
          @click="handleApply"
          :loading="isLoading"
          :disabled="isLoading"
        >
          {{ $t("action.next") }}
          <v-icon right v-text="'mdi-arrow-right'" class="ml-4" size="22" />
        </v-btn>
      </div>
    </v-footer>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { setFirebaseEvent } from "@/helpers/services/registerAnalytics.js";
import { formatDateAndHour } from "@/helpers/services/utils";
import CardTemplate from "@/components/sign/general/CardTemplate.vue";
import TemplatePreview from "@/components/sign/general/TemplatePreview.vue";

import {
  successMessages,
  errorMessages,
} from "@/helpers/variables/snackbarMessages";

export default {
  name: "UpdateUserApplySignature",
  components: { CardTemplate, TemplatePreview },

  props: {},

  data() {
    return {
      localLoading: false,
      expandTemplatesTypes: 0,
      search: "",
      templateSelected: [],
      lastTemplateSelected: null,
      sendAsPreview: null,
      sendAsSelected: [],
      showApplyDialog: false,
      alertHeight: 80,
      thisHeight: 1000,
      loadingTemplates: false,
      loadingSendAs: false,
      sendAsList: [],
      userApplySignatureStep: 5,
    };
  },

  computed: {
    ...mapGetters([
      "userById",
      "mainDomain",
      "companyHasSign",
      "customTemplates",
      "defaultTemplates",
      "allTemplates",
      "loading",
      "userSendAs",
      "currentOnboardingStep",
    ]),

    isLoading() {
      return (
        this.loading ||
        this.localLoading ||
        this.loadingTemplates ||
        this.loadingSendAs
      );
    },

    isGmailEnabled() {
      return this.userById?.is_gmail_enabled;
    },

    hasPermission() {
      const not_valid_status = [
        "PLAN_TRIAL_ENDED",
        "PRODUCT_DOES_NOT_EXIST_IN_PLANS",
      ];
      if (not_valid_status.includes(this.companyHasSign)) return false;
      else return true;
    },

    selectedTemplate() {
      return this.templateSelected?.[0] || {};
    },

    templatePreviewHtml() {
      if (this.selectedTemplate.html) {
        return this.selectedTemplate.html;
      } else if (this.sendAsPreview) {
        return this.sendAsPreview.signature;
      }
      return "";
    },

    emails() {
      return this.sendAsSelected.map((sendAs) => sendAs.email);
    },
  },

  watch: {
    userById: {
      async handler(userById) {
        if (!userById?.id_google) return;
        this.sendAsList = this.userSendAs(this.userById.id_google) || [];
        this.sendAsPreview = this.sendAsList?.[0];
        this.sendAsSelected = this.sendAsPreview ? [this.sendAsPreview] : [];
        if (this.isGmailEnabled && !this.sendAsList?.length) this.getSendAs();
        if (!this.allTemplates.length) await this.getAllTemplates();
      },
      immediate: true,
    },

    currentOnboardingStep(currentStep, oldStep) {
      if (this.userApplySignatureStep === oldStep && currentStep > oldStep) {
        this.handleApply();
      }
    },
  },

  methods: {
    ...mapMutations(["setPermissionDialog", "setLoading", "setSnackBar"]),

    ...mapActions([
      "getDefaultTemplates",
      "getCustomTemplates",
      "getSendAsByUser",
      "completeMission",
      "setSignatureToUsers",
    ]),

    setFirebaseEvent,

    handleApply() {
      if (
        this.isGmailEnabled &&
        this.selectedTemplate?.html &&
        this.sendAsSelected?.length
      ) {
        this.applyTemplate();
      } else if (this.userApplySignatureStep === this.currentOnboardingStep) {
        this.$emit("next");
      }
    },

    saveAnalytics(log) {
      if (this.mainDomain) {
        this.setFirebaseEvent(this.mainDomain, log.event, log.click);
      }
    },

    showPermissionProductDialog() {
      if (this.hasPermission) return null;
      const dialog = {
        show: true,
        status: "PRODUCT_IN_DISABLED_PLAN",
        app: "conecta_sign",
      };
      this.setPermissionDialog(dialog);
    },

    reset() {
      this.templateSelected = [];
      this.lastTemplateSelected = null;
      this.sendAsPreview = null;
      this.sendAsSelected = [];
      this.getSendAs();
    },

    save() {
      this.localLoading = true;

      const log = {
        event: "update_user_apply_signature_onboarding",
        click: "Botão de aplicar assinatura do usuário e avançar",
      };
      this.saveAnalytics(log);

      this.showApplyDialog = true;

      this.localLoading = false;
    },

    async applyTemplate() {
      this.setLoading(true);

      const payload = {
        emails: this.emails,
        template_key: this.selectedTemplate.id,
      };

      await this.setSignatureToUsers(payload)
        .then(() => {
          this.setSnackBar({
            message: successMessages.set_signature_to_users,
            show: true,
          });

          this.completeMission("apply_email_signature");
          this.save();
          this.reset();

          if (this.userApplySignatureStep === this.currentOnboardingStep) {
            this.$emit("next");
          }
        })
        .catch((error) => {
          this.setSnackBar({
            message: errorMessages.set_signature_to_users,
            show: true,
            color: "error",
          });
          console.error("applyTemplate(): ", error);
        })
        .finally(() => {
          this.setLoading();
          this.reset();
        });
    },

    async getAllTemplates() {
      this.loadingTemplates = true;
      await this.getCustomTemplates();
      await this.getDefaultTemplates();
      this.loadingTemplates = false;
    },

    formatDateAndHour,

    async getSendAs() {
      this.loadingSendAs = true;
      await this.getSendAsByUser(this.userById);
      this.sendAsList = this.userSendAs(this.userById.id_google) || [];
      this.sendAsPreview = this.sendAsList?.[0];
      this.sendAsSelected = this.sendAsPreview ? [this.sendAsPreview] : [];
      this.loadingSendAs = false;
    },

    itemIsSelected(item) {
      return this.sendAsSelected?.find(
        (sendAsSelected) => sendAsSelected.email == item.email
      );
    },

    clickSelectedEmail(item) {
      this.sendAsPreview = item;
      if (item.signature && !this.lastTemplateSelected) {
        if (this.templateSelected?.length) {
          this.lastTemplateSelected = this.templateSelected[0];
        }
        this.templateSelected = [];
      } else if (this.lastTemplateSelected && !this.templateSelected?.length) {
        this.templateSelected = [this.lastTemplateSelected];
        this.lastTemplateSelected = null;
      }
    },
  },
  async beforeMount() {
    if (!this.hasPermission) {
      this.showPermissionProductDialog();
    }
  },
  mounted() {
    const _thisEl = this.$el;
    this.thisHeight = _thisEl.clientHeight;
    const alertEl = this.$refs.alert.$el;
    this.alertHeight = alertEl.clientHeight;
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const target = entry.target;
        if (target.id == alertEl.id) {
          this.alertHeight = target.clientHeight;
        } else if (target.id == _thisEl.id) {
          this.thisHeight = target.clientHeight;
        }
      }
    });
    resizeObserver.observe(_thisEl);
    resizeObserver.observe(alertEl);
  },
};
</script>
