<template>
  <v-expansion-panels v-model="panel" flat multiple>
    <v-expansion-panel :class="`${outlined ? 'outlined' : ''}`">
      <v-progress-linear
        v-if="showLoading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-expansion-panel-header
        class="grey--text text--darken-2 font-weight-medium text-body-1"
      >
        <v-row align-content="center">
          <v-col class="d-block" cols="9">
            <div class="mx-0 pt-0 px-0">
              {{ $t("common.addUserToSharedDrivers") }}
            </div>
          </v-col>
        </v-row>
      </v-expansion-panel-header>

      <v-expansion-panel-content
        :class="`pb-0 ${overlayScopes ? 'pa-4' : ''}`"
        style="position: relative"
      >
        <v-card-text class="mx-0 pt-0 px-0">
          {{ $t("common.addUserToSharedDriversDescription") }}
        </v-card-text>

        <v-simple-table class="mb-4">
          <template v-slot:default>
            <tbody>
              <tr v-for="drive in userDrives" :key="drive.id">
                <td>{{ drive.name }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-tooltip :disabled="!isPermissionDenied" bottom>
          <template v-slot:activator="{ on, attrs }">
            <div style="width: fit-content" v-bind="attrs" v-on="on">
              <v-btn
                :color="`${isOnboardingPage ? 'primarySuite' : 'accent'}`"
                @click="showDialogAddDrive = true"
                :disabled="
                  showLoading || isPermissionDenied || !companyHasSharedDrives
                "
                :loading="showLoading"
                class="mt-0 elevation-0 text-none font-weight-medium text-body-2 white--text"
              >
                {{ $t("common.addSharedDrive") }}
              </v-btn>
            </div>
          </template>
          <span v-if="isPermissionDenied">
            {{ $t("onboardingNewUser.verifyPlanDrive") }}
          </span>
          <span v-if="!isPermissionDenied && !companyHasSharedDrives">
            {{ $t("onboardingNewUser.companySharedDrivesNotFound") }}
          </span>
        </v-tooltip>

        <!-- DIALOG ADC NOVO GRUPO -->
        <HeaderDialog
          id="drive_to_user"
          :title="$t('common.addSharedDrive')"
          :show="showDialogAddDrive"
          width="600"
          :loading="showLoading"
          :disabled-action="!!!selectedDrive.id || !role"
          @action="addUserToSharedDrive()"
          @close="reset()"
          :persistent="false"
        >
          <template v-slot:body>
            <p class="pt-4 pb-0 mb-0 mr-2">
              {{ $t("common.addSharedDriveDescription") }}:
            </p>

            <v-autocomplete
              v-model="selectedDrive"
              :items="drivesUserAreNotIn"
              item-text="name"
              :placeholder="$t('common.companyDrive')"
              class="mt-0"
              return-object
            ></v-autocomplete>
            <p class="pb-0 mb-0 pt-3">{{ $t("common.defineTheRole") }}:</p>

            <v-autocomplete
              v-model="role"
              :items="rolesOptions"
              item-text="value"
              item-value="key"
              :placeholder="$t('userInformations.office')"
              class="mt-0"
            ></v-autocomplete>
          </template>
        </HeaderDialog>
        <!-- OVERLAY FOR SCOPES MISSING  -->
        <OverlayScopes
          :overlay="overlayScopes"
          :functionality="$t('common.addUserToSharedDrivers')"
          dense
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import HeaderDialog from "@/components/base/HeaderDialog.vue";
import { setFirebaseEvent } from "@/helpers/services/registerAnalytics.js";
import {
  INCREMENTAL_SCOPES,
  MARKETPLACE_APP_URL,
} from "@/helpers/variables/scopes";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  errorMessages,
  successMessages,
} from "@/helpers/variables/snackbarMessages";
import OverlayScopes from "../general/OverlayScopes.vue";

export default {
  name: "UserSharedDrives",
  components: { HeaderDialog, OverlayScopes },
  props: {
    user: { type: Object, default: () => {} },
    outlined: { type: Boolean, default: false },
    isPanelOpened: { type: Boolean, default: false },
  },
  data() {
    return {
      panel: [this.isPanelOpened ? 0 : 1],
      showLoading: false,
      showDialogAddDrive: false,
      userDrives: [],
      selectedDrive: {},
      role: "",
      loadingScopes: false,
      overlayScopes: false,
      disabled: false,
      MARKETPLACE_APP_URL,
      isPermissionDenied: false,
    };
  },

  computed: {
    ...mapGetters([
      "currentUser",
      "companySharedDrives",
      "mainDomain",
      "userById",
      "isAdmin",
      "scopeCode",
    ]),

    rolesOptions() {
      return [
        { key: "organizer", value: this.$t("common.roles.organizer") },
        { key: "fileOrganizer", value: this.$t("common.roles.fileOrganizer") },
        { key: "writer", value: this.$t("common.roles.writer") },
        { key: "commenter", value: this.$t("common.roles.commenter") },
        { key: "reader", value: this.$t("common.roles.reader") },
      ];
    },

    drivesUserAreNotIn() {
      return this.companySharedDrives.filter(
        ({ id }) => !this.userDrives.find((item) => item.id === id)
      );
    },

    isOnboardingPage() {
      if (this.$route.name === "Onboarding") return true;
      return false;
    },

    key() {
      return this.user.key || this.userById.key;
    },

    companyHasSharedDrives() {
      return this.companySharedDrives.length;
    },
  },

  methods: {
    ...mapActions([
      "insertUserOnSharedDrives",
      "getUserSharedDrives",
      "getCompanySharedDrives",
      "checkScopes",
    ]),
    ...mapMutations(["setSnackBar"]),

    setFirebaseEvent,

    saveAnalytics() {
      if (this.mainDomain) {
        const event = this.isOnboardingPage
          ? "onboarding_add_user_on_shared_drive"
          : "add_user_on_shared_drive";
        const click = "Botão de adicionar drive compartilhado para o usuário";
        this.setFirebaseEvent(this.mainDomain, event, click);
      }
    },

    addUserToSharedDrive() {
      this.showLoading = true;

      const data = {
        payload: {
          drive_id: this.selectedDrive.id,
          role: this.role,
        },
        key: this.user.key,
      };

      this.insertUserOnSharedDrives(data)
        .then(() => {
          this.userDrives.push(this.selectedDrive);
          this.setSnackBar({
            show: true,
            message: successMessages.apply_24hours,
          });
        })
        .catch((error) => {
          console.error("insertUserOnSharedDrives()", error);
          const statusCode = error.response?.status;
          const code = error.response?.data?.code;
          const { unknown, SHARED_DRIVE_FORBIDDEN } = errorMessages;
          if (statusCode === 403) {
            this.setSnackBar({
              show: true,
              message: SHARED_DRIVE_FORBIDDEN,
              color: "error",
            });
          } else if (
            code !== "INVALID_GOOGLE_ACCESS_TOKEN" ||
            statusCode !== 401
          ) {
            this.setSnackBar({
              show: true,
              message: unknown,
              color: "error",
            });
          }
        })
        .finally(() => {
          this.reset();
          this.saveAnalytics();
        });
    },

    async getUserDrives() {
      await this.getUserSharedDrives(this.key)
        .then((response) => {
          this.userDrives = response.data;
        })
        .catch((error) => {
          console.log("getUserDrives()", error);
          const {
            response: { data: { code, status } = { code: null, status: null } },
          } = error;
          if (status === "PERMISSION_DENIED") {
            this.isPermissionDenied = true;
          } else if (code !== "INVALID_GOOGLE_ACCESS_TOKEN") {
            this.setSnackBar({
              show: true,
              is_key: true,
              message_key: "USER_DRIVES_NOT_FOUND",
              color: "error",
            });
          }
        })
        .finally(() => {
          this.reset();
        });
    },

    reset() {
      this.showDialogAddDrive = false;
      this.selectedDrive = {};
      this.showLoading = false;
      this.role = "";
    },

    setDrives() {
      this.showLoading = true;
      if (this.key && !this.userDrives.length) {
        this.getUserDrives(this.user.key);
      } else {
        this.showLoading = false;
      }
    },
    async checkDriveScope() {
      const driveScope = INCREMENTAL_SCOPES.ADMIN_DRIVE;
      if (this.scopeCode(driveScope)) return true;
      this.loadingScopes = true;
      this.showLoading = true;

      const payload = {
        scopes: [driveScope],
      };

      await this.checkScopes(payload)
        .then(() => {
          this.overlayScopes = false;
          this.disabled = false;
          return true;
        })
        .catch(() => {
          this.overlayScopes = true;
          this.disabled = true;
          return false;
        })
        .finally(() => {
          this.loadingScopes = false;
        });
    },
  },

  watch: {
    user() {
      this.setDrives();
    },
    async panel(_, newV) {
      if (newV.length === 1 && !this.companyHasSharedDrives) {
        if (this.checkDriveScope()) {
          await this.getCompanySharedDrives();
        }
        this.loadingScopes = false;
      }
      this.setDrives();
    },
  },

  mounted() {
    if (this.isPanelOpened) {
      this.setDrives();
    }
  },

  async beforeMount() {
    if (this.isPanelOpened) {
      if (this.checkDriveScope() && !this.companyHasSharedDrives) {
        await this.getCompanySharedDrives();
      }
      this.loadingScopes = false;
    }
  },
};
</script>
