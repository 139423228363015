<template>
  <v-container fluid class="d-flex flex-column fill-height">
    <div @click="showPermissionProductDialog">
      <v-card
        tile
        flat
        class="elevation-0 overflow-y-auto ma-auto"
        max-width="90vw"
        height="calc(89vh - 80px)"
        :loading="loading"
      >
        <v-card-title class="pa-2 mt-5">
          <span class="mr-4">{{ $t("common.userInfos") }} </span>
          <TooltipIcon
            right
            color="info"
            icon="mdi-information"
            :label="$t('userInformations.userInfosDescription')"
          />
        </v-card-title>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent=""
          class="mt-2 mb-5"
          :disabled="!hasPermission"
        >
          <v-row class="ma-0 pa-0">
            <v-col
              v-for="tag in contactTags"
              :key="tag"
              cols="12"
              sm="12"
              md="8"
              lg="6"
              class="d-flex"
            >
              <v-col
                cols="7"
                sm="5"
                md="6"
                lg="6"
                align-self="center"
                class="ma-0 pa-0"
              >
                <v-card-text
                  class="font-weight-medium body-2 py-0 pl-2 d-flex align-center"
                  >{{ $t(userEditableTags[tag].translatePath) }}
                </v-card-text>
                <v-card-text class="body-2 py-0 pl-2">{{
                  userEditableTags[tag].label
                }}</v-card-text>
              </v-col>

              <v-col
                class="text-left"
                align-self="start"
                cols="5"
                sm="7"
                md="12"
                lg="6"
              >
                <v-text-field
                  v-model="tagsEdit[tag]"
                  class="pt-0 pr-2 my-0 body-2"
                  autocomplete="off"
                  hide-details="auto"
                  clearable
                  :label="
                    userEditableTags[tag].labelGoogle ||
                    $t(userEditableTags[tag].translatePath) ||
                    userEditableTags[tag].keyName
                  "
                  :rules="getRules(tag)"
                  @input="saveDataOnLocalStorage"
                  @change="saveDataOnLocalStorage"
                ></v-text-field>
              </v-col>
            </v-col>
          </v-row>
          <v-row v-for="(tag, i) in employeeTags" :key="i" class="ma-0 pa-0">
            <v-col cols="12" sm="" lg="3" align-self="center">
              <v-card-text
                class="font-weight-medium body-2 py-0 pl-2 d-flex align-center"
              >
                <span class="mr-4">{{
                  $t(userEditableTags[tag].translatePath)
                }}</span>
                <TooltipIcon
                  v-if="tag === 'sign_photo'"
                  right
                  color="info"
                  icon="mdi-information"
                  :label="$t('userInformations.signaturePhotoUrlTooltipText')"
                />
              </v-card-text>
              <v-card-text class="body-2 py-0 pl-2">{{
                userEditableTags[tag].label
              }}</v-card-text>
            </v-col>

            <v-col class="text-left" align-self="start" cols="12" sm="7" lg="9">
              <div v-if="userEditableTags[tag].label === '[*USER_SIGN_PHOTO*]'">
                <v-avatar
                  v-if="!hasAcceptedDriveScopes"
                  size="60"
                  class="mb-4"
                  v-ripple
                >
                  <img v-if="!tagsEdit[tag]" :src="getPreviewTagImage" />
                  <img v-else :src="tagsEdit[tag]" />
                </v-avatar>

                <div v-else class="d-flex align-center mb-4">
                  <v-badge
                    bordered
                    bottom
                    color="accent"
                    offset-x="15"
                    offset-y="40"
                    icon="mdi-pencil"
                  >
                    <v-avatar
                      @click="openUploadImageDialog(tag, tagsEdit[tag])"
                      size="60"
                      class="mb-6 avatar"
                      v-ripple
                    >
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <img
                            v-if="!tagsEdit[tag]"
                            v-bind="attrs"
                            v-on="on"
                            :src="getPreviewTagImage"
                          />
                          <img
                            v-else
                            :src="tagsEdit[tag]"
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                        <span>{{ $t("userInformations.addImage") }}</span>
                      </v-tooltip>
                    </v-avatar>
                  </v-badge>

                  <div class="ml-6 d-flex flex-column">
                    <v-btn
                      elevation="0"
                      outlined
                      text
                      small
                      class="text-none mb-4"
                      @click="openUploadImageDialog(tag, tagsEdit[tag])"
                    >
                      <v-icon left dark> mdi-cloud-upload-outline </v-icon>
                      {{ $t("userInformations.upload") }}
                    </v-btn>
                  </div>
                </div>

                <v-text-field
                  color="accent"
                  v-model="tagsEdit[tag]"
                  @input="updateURL(tag)"
                  class="pt-0 pr-2 my-0 body-2"
                  autocomplete="off"
                  clearable
                  :label="userEditableTags[tag].label"
                ></v-text-field>
              </div>

              <v-text-field
                v-else
                v-model="tagsEdit[tag]"
                class="pt-0 pr-2 my-0 body-2"
                autocomplete="off"
                clearable
                :label="
                  userEditableTags[tag].labelGoogle ||
                  $t(userEditableTags[tag].translatePath) ||
                  userEditableTags[tag].keyName
                "
                :rules="[rules.maxUserInfo]"
                :persistent-hint="userEditableTags[tag]?.hint ? true : false"
                :hint="
                  $t(userEditableTags[tag].translateHintPath) ||
                  userEditableTags[tag]?.hint
                "
                @input="saveDataOnLocalStorage"
                @change="saveDataOnLocalStorage"
              >
                <template v-slot:message="{ message }">
                  <div
                    style="color: #1948a3"
                    class="d-flex align-center"
                    v-if="
                      userEditableTags[tag].label == '[*MEETING_SCHEDULE_LINK*]'
                    "
                  >
                    <v-icon small class="mr-1" color="#2196f3"
                      >mdi-information</v-icon
                    >
                    <span v-html="message" />
                  </div>
                  <span v-else v-html="message" />
                </template>
              </v-text-field>

              <v-alert
                dense
                outlined
                color="warning"
                v-if="
                  userEditableTags[tag].keyName == '[*USER_SIGN_PHOTO*]' &&
                  notGoogleDriveUrl
                "
                class="d-flex align-center caption"
              >
              </v-alert>
            </v-col>
          </v-row>
          <v-card-title class="pa-2 mt-5">
            <span class="mr-4">{{ $t("common.customizedInformation") }}</span>
          </v-card-title>
          <v-row v-for="tag in userCustomTags" :key="tag" class="ma-0 pa-0">
            <v-col cols="12" sm="4" lg="3" align-self="center">
              <v-card-text
                class="font-weight-medium body-2 py-0 pl-2 d-flex align-center"
                ><span class="mr-4">
                  {{ $t(userEditableTags[tag].translatePath) }}
                </span>
                <TooltipIcon
                  v-if="userEditableTags[tag].info"
                  right
                  color="info"
                  icon="mdi-information"
                  :label="$t(userEditableTags[tag].infoTranslatePath)"
                />
              </v-card-text>
              <v-card-text class="body-2 py-0 pl-2">{{
                userEditableTags[tag].label
              }}</v-card-text>
            </v-col>

            <v-col class="text-left" align-self="start" cols="12" sm="7" lg="9">
              <v-text-field
                :data-testid="`${tag}`"
                v-model="tagsEdit[tag]"
                hide-details="auto"
                @input="updateURL(tag)"
                class="pt-0 pr-2 my-0 body-2"
                autocomplete="off"
                :placeholder="
                  $t(userEditableTags[tag].translatePlaceholderPath)
                "
                :label="$t(userEditableTags[tag].translatePath)"
                @change="saveDataOnLocalStorage"
                clearable
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row v-for="(tag, i) in customTagsEdit" :key="i" class="ma-0 pa-0">
            <v-col
              cols="12"
              sm="4"
              lg="3"
              align-self="start"
              class="px-0 mx-0 d-flex align-end"
            >
              <div>
                <v-card-text class="font-weight-medium body-2 py-0 ml-1"
                  >{{ tag.name }}
                </v-card-text>
                <v-card-text
                  class="grey--text text--darken-2 body-2 py-0 ml-1"
                  :style="
                    $vuetify.breakpoint.mdAndDown && $vuetify.breakpoint.smAndUp
                      ? 'inline-size: 183px'
                      : ''
                  "
                  >{{ tag.tag }}</v-card-text
                >
              </div>
            </v-col>
            <v-col cols="12" sm="7" lg="9" align-self="end">
              <v-text-field
                :placeholder="tag.name"
                @input="$forceUpdate(), saveDataOnLocalStorage"
                v-model="tag.value"
                hide-details="auto"
                color="grey"
                class="pt-0 my-0 body-2"
                clearable
                :rules="tagRules"
                @change="saveDataOnLocalStorage"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </div>

    <v-divider style="width: 90vw"></v-divider>
    <v-footer
      rounded
      style="height: 70px"
      color="background"
      class="border-bottom rounded-xl mt-3"
      width="90vw"
    >
      <v-btn
        text
        height="52"
        color="secondary"
        style="border: 2px solid grey !important"
        class="mx-auto text-none text-body-1 font-weight-medium px-8"
        outlined
        @click="$emit('back')"
        :disabled="loading"
      >
        <v-icon right v-text="'mdi-arrow-left'" class="mr-4" size="22" />
        {{ $t("common.back") }}
      </v-btn>

      <v-spacer />
      <span :v-show="false" :disabled="thereWereNoChanges"></span>
      <div>
        <v-btn
          height="52"
          color="accent"
          class="mx-auto text-none text-body-1 font-weight-bold px-8 mr-0"
          @click="handleSave"
          :disabled="loading || !valid || !hasPermission"
          :loading="loading"
        >
          {{ $t("action.next") }}
          <v-icon right v-text="'mdi-arrow-right'" class="ml-4" size="22" />
        </v-btn>
      </div>
    </v-footer>

    <HeaderDialog
      id="theweekend"
      :title="$t('userInformations.addImage')"
      color="primary"
      :show="showUploadImageDialog"
      :loading="uploadingImageLoading"
      width="700"
      :disabledAction="!file"
      showCancel
      :closeText="$t('action.cancel')"
      button-class="text-none text-subtitle-1 font-weight-medium elevation-0"
      @close="closeImageUploadDialog"
      @action="uploadImage"
    >
      <template v-slot:body>
        <v-row class="flex-column justify-center">
          <v-avatar size="200" class="mb-4 mt-8 mx-auto">
            <img v-if="!imageURLPreview" :src="getPreviewTagImage" />
            <img v-else :src="imageURLPreview" />
          </v-avatar>

          <div class="mx-4">
            <v-file-input
              accept="image/*"
              :label="$t('userInformations.selectImage')"
              :hint="$t('userInformations.imageSavedOnDrive')"
              persistent-hint
              @change="handleFileUpload"
            ></v-file-input>
          </div>
        </v-row>
      </template>
    </HeaderDialog>
  </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  errorMessages,
  successMessages,
} from "@/helpers/variables/snackbarMessages";
import { userTags, userEditableTags } from "@/helpers/variables/tags";
import { changeDriveURL } from "@/helpers/services/images";
import { setFirebaseEvent } from "@/helpers/services/registerAnalytics.js";
import TooltipIcon from "../base/TooltipIcon.vue";
import { setUserInFormData } from "@/helpers/services/utils";
import { INCREMENTAL_SCOPES } from "@/helpers/variables/scopes";
export default {
  name: "UpdateUserSignatureInfo",
  components: { TooltipIcon },

  props: {},

  data() {
    return {
      userTags,
      userEditableTags,
      valid: true,
      tagsEdit: {},
      customTagsEdit: {},
      loading: false,
      notGoogleDriveUrl: false,
      user: {},
      updateUserOnWorkspace: false,
      rules: {
        maxPhone: (v) =>
          v?.length <= 20 || !v || this.$t("textFieldRules.max20characters"),
        maxUserInfo: (v) =>
          v?.length <= 60 || !v || this.$t("textFieldRules.max60characters"),
      },
      tagRules: [
        (v) =>
          String(v)?.length <= 1490 ||
          "O valor não pode ter mais que 1490 caracteres",
      ],
      file: null,
      userSelected: null,
      userSignatureInfoStep: 3,
      uploadingImageLoading: false,
      showUploadImageDialog: false,
      imageURLPreview: null,
      selectedTagImage: null,
      selectedTagImageURL: null,
    };
  },

  computed: {
    ...mapGetters([
      "usersCustomTags",
      "usersCustomTagKeys",
      "mainDomain",
      "token",
      "companyHasSign",
      "users",
      "currentOnboardingStep",
      "userById",
      "hasAcceptedDriveScopes",
      "scopeCode",
    ]),

    auth() {
      return {
        headers: {
          Authorization: this.token,
        },
      };
    },

    getPreviewTagImage() {
      return this.selectedTagImageURL || require("@/assets/user-default.png");
    },

    thereWereNoChanges() {
      const payload = this.createPayload();
      return Object.keys(payload).length === 0;
    },

    userSelectedInStorage() {
      const formData = JSON.parse(localStorage.getItem("formData")) || {};
      return formData.user;
    },

    customTags() {
      return this.userSelected.custom_tags || {};
    },

    hasPermission() {
      const not_valid_status = [
        "PLAN_TRIAL_ENDED",
        "PRODUCT_DOES_NOT_EXIST_IN_PLANS",
      ];
      if (not_valid_status.includes(this.companyHasSign)) return false;
      else return true;
    },

    contactTags() {
      const { phone, mobile, whatsapp_number, departament, position, ramal } =
        this.userEditableTags;
      return Object.keys({
        phone,
        mobile,
        whatsapp_number,
        departament,
        position,
        ramal,
      });
    },

    employeeTags() {
      const { sign_photo, calendar_schedules_link } = this.userEditableTags;

      return Object.keys({
        sign_photo,
        calendar_schedules_link,
      });
    },

    userCustomTags() {
      const { user_custom_1, user_custom_2 } = this.userEditableTags;

      return Object.keys({
        user_custom_1,
        user_custom_2,
      });
    },
  },

  methods: {
    ...mapMutations([
      "setSnackBar",
      "setUserById",
      "setPermissionDialog",
      "setAcceptedDriveScopes",
    ]),
    ...mapActions(["updateUserSignatureInfo", "checkScopes"]),

    handleFileUpload(imageFile) {
      if (imageFile) {
        this.imageURLPreview = URL.createObjectURL(imageFile);
      }
      this.file = imageFile;
    },

    closeImageUploadDialog() {
      this.showUploadImageDialog = false;
      this.imageURLPreview = null;
      this.file = null;
      this.selectedTagImage = null;
      this.selectedTagImageURL = null;
    },

    uploadImage() {
      if (!this.file) return;

      this.uploadingImageLoading = true;
      const formData = new FormData();
      formData.append("image", this.file);
      formData.append("image_field_key", "sign_photo");

      const baseUrl = `${process.env.VUE_APP_API_BASE_URL}/users`;

      const url = `${baseUrl}/${this.userSelected["key"]}/upload-image`;

      this.$axios
        .post(url, formData, this.auth)
        .then(({ data }) => {
          this.tagsEdit[this.selectedTagImage] = data.shared_drive_url;
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
          this.setSnackBar({
            message: errorMessages["FAILED_TO_UPLOAD_IMAGE"],
            color: "error",
            show: true,
            timeout: 8000,
          });
        })
        .finally(() => {
          this.uploadingImageLoading = false;
          this.closeImageUploadDialog();
        });
    },

    openUploadImageDialog(tag, tagImageURL) {
      this.selectedTagImage = tag;
      this.selectedTagImageURL = tagImageURL;
      this.showUploadImageDialog = true;
    },

    setUserSelectedById(user = null) {
      this.userSelected =
        user ||
        this.userSelectedInStorage ||
        this.users.find((user) => user.id_google === this.userId) ||
        {};

      localStorage.setItem("userSelected", JSON.stringify(this.userSelected));
      this.setCustomTagsEdit();

      setUserInFormData(this.userSelected);
    },

    saveAnalytics(log) {
      if (this.mainDomain) {
        setFirebaseEvent(this.mainDomain, log.event, log.click);
      }
    },

    saveDataOnLocalStorage() {
      localStorage.setItem(
        "formSignatureInfoData",
        JSON.stringify(this.tagsEdit)
      );
    },

    setCustomTagsEdit() {
      const customTagsEdit = this.usersCustomTagKeys.reduce((acc, tagKey) => {
        const customTag = this.usersCustomTags[tagKey];
        customTag.value = this.customTags[tagKey]?.value || "";
        acc[tagKey] = customTag;

        return acc;
      }, {});

      this.customTagsEdit = customTagsEdit;
    },

    getTagsUser() {
      this.tagsEdit = userTags.reduce(
        (a, e) => ((a[e] = this.userSelected[e] || ""), a),
        {}
      );
    },

    getRules(tag) {
      const phoneTag = ["phone", "mobile", "whatsapp_number"];
      if (phoneTag.includes(tag)) return [this.rules.maxPhone];
      else return [this.rules.maxUserInfo];
    },

    createPayload() {
      let payload = {};

      Object.keys(this.tagsEdit).forEach((tag) => {
        if (this.tagsEdit[tag] !== this.userSelected[tag] && tag != "photo") {
          payload[tag] = this.tagsEdit[tag];
        }
      });

      let customTagsEdited = false;

      const { custom_tags } = this.userSelected;

      if (!custom_tags) {
        return payload;
      }
      this.usersCustomTagKeys.forEach((tag) => {
        if (this.customTagsEdit[tag]?.value != custom_tags[tag]?.value) {
          if (!payload.custom_tags) {
            payload.custom_tags = {};
          }

          payload.custom_tags[tag] = this.customTagsEdit[tag];

          customTagsEdited = true;
        }
      });

      if (!customTagsEdited) {
        delete payload.custom_tags;
      }

      return payload;
    },

    handleSave() {
      if (!this.thereWereNoChanges) {
        this.save();
      } else if (this.userSignatureInfoStep === this.currentOnboardingStep) {
        this.$emit("next");
      }
    },

    save() {
      this.loading = true;

      const payload = {
        id_user: this.userId,
        editable_data: this.createPayload(),
      };

      delete payload.editable_data.email;
      delete payload.editable_data.name;

      const log = {
        event: "update_user_signature_info_onboarding",
        click: "Botão de salvar informações de assinatura do usuário e avançar",
      };
      this.saveAnalytics(log);

      const { tags_update, PLAN_EXPIRED } = errorMessages;

      this.updateUserSignatureInfo(payload)
        .then(({ data }) => {
          this.setSnackBar({
            show: true,
            message: successMessages.saves_changes,
            color: "success",
          });
          this.setUserSelectedById(data.user);
          this.userSelected = data.user;
          this.setUserById(data.user);
          this.updateUserOnWorkspace = true;

          if (this.userSignatureInfoStep === this.currentOnboardingStep)
            this.$emit("next");
        })
        .catch((error) => {
          console.error("updateUserSignatureInfo: ", error);
          if (error.response.data.code === "PLAN_TRIAL_ENDED") {
            this.setSnackBar({
              show: true,
              message: PLAN_EXPIRED,
              color: "error",
            });
          } else {
            this.setSnackBar({
              show: true,
              message: tags_update,
              color: "error",
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    assignWorkspacePayload(payload) {
      let workspacePayload = [];
      const google_workspace_editable_data = [
        "phone",
        "mobile",
        "departament",
        "position",
      ];
      google_workspace_editable_data.map((workspaceData) => {
        if (workspaceData in payload) {
          workspacePayload.push(workspaceData);
        }
      });
      return workspacePayload;
    },
    async updateUserInfoOnWorkspace() {
      const url = `${process.env.VUE_APP_API_BASE_URL}/conecta-sign/sync-users-in-workspace`;
      const workspacePayload = {
        emails: [this.userSelected.email],
        workspace_data: this.assignWorkspacePayload(this.createPayload()),
      };
      return this.$axios.post(url, workspacePayload, {
        headers: {
          Authorization: this.token,
        },
      });
    },

    updateURL(tag) {
      this.tagsEdit[tag] = changeDriveURL(this.tagsEdit[tag]);
      this.saveDataOnLocalStorage();
    },

    showPermissionProductDialog() {
      if (this.hasPermission) return null;
      const dialog = {
        show: true,
        status: "PRODUCT_IN_DISABLED_PLAN",
        app: "conecta_sign",
      };
      this.setPermissionDialog(dialog);
    },
  },
  watch: {
    updateUserOnWorkspace() {
      if (this.updateUserOnWorkspace) {
        this.updateUserInfoOnWorkspace();
      }
    },

    userSelected() {
      this.getTagsUser();
    },

    users() {
      this.setUserSelectedById();
    },

    userById(value) {
      this.userSelected = value;
      this.setCustomTagsEdit();
    },

    currentOnboardingStep(currentStep, oldStep) {
      if (this.userSignatureInfoStep === oldStep && currentStep > oldStep) {
        this.handleSave();
      }
    },
  },
  created() {
    this.userId = JSON.parse(localStorage.getItem("userId"));
    this.setUserSelectedById();
  },

  async beforeMount() {
    const driveScope = INCREMENTAL_SCOPES.ADMIN_DRIVE;
    if (!this.scopeCode(driveScope)) {
      const payload = {
        scopes: [driveScope],
      };

      await this.checkScopes(payload)
        .then(() => {
          this.setAcceptedDriveScopes(true);
        })
        .catch(() => {
          this.setAcceptedDriveScopes(false);
        });
    }
    if (!this.hasPermission) {
      this.showPermissionProductDialog();
    }
    this.setCustomTagsEdit();
    if (localStorage.getItem("formSignatureInfoData")) {
      this.tagsEdit = JSON.parse(localStorage.getItem("formSignatureInfoData"));
    }
  },
};
</script>
<style scoped></style>
